<template>

  <v-row class="d-flex justify-end pa-2">
    <v-btn :key="$vuetify.theme.dark" @click="$vuetify.theme.dark = !$vuetify.theme.dark" class="ma-2"
           color="primary" large outlined small icon>
      <v-icon class="primary--text">
        {{ $vuetify.theme.dark ? "brightness_2" : "wb_sunny" }}
      </v-icon>
    </v-btn>

    <v-menu
        offset-y
        left
        nudge-bottom="14"
        min-width="230"
        content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar size="45px" class="ma-2" v-bind="attrs" v-on="on">
          <v-img :src="avatar"></v-img>
        </v-avatar>
      </template>
      <v-list>
        <div class="pb-3 pt-2 px-4">
          <v-avatar size="40px">
            <v-img :src="avatar"></v-img>
          </v-avatar>
          <div
              class="d-inline-flex flex-column justify-center ms-3"
              style="vertical-align: middle"
          >
            <span class="text--primary font-weight-semibold mb-n1">
              {{name}}
            </span>
            <small class="text--disabled text-capitalize">@{{nickname}}</small>
          </div>
        </div>


        <!--        <v-divider></v-divider>-->
        <!--        &lt;!&ndash; Profile &ndash;&gt;-->
        <!--        <v-list-item link>-->
        <!--          <v-list-item-icon class="me-2">-->
        <!--            <v-icon size="22">account_box</v-icon>-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-content>-->
        <!--            <v-list-item-title>Profile</v-list-item-title>-->
        <!--          </v-list-item-content>-->
        <!--        </v-list-item>-->

        <v-divider></v-divider>
        <!-- Logout -->
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22"> arrow_forward</v-icon>
          </v-list-item-icon>
          <v-list-item-content @click="logout()">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-row>
</template>
<script>
export default {
  props: ['hover'],
  computed: {
    avatar() {
      if (this.$store) {
        this.currentUser = this.$store.state.current_user
        return this.currentUser && this.currentUser.userInfo && this.currentUser.userInfo.avatarUrl ? this.currentUser.userInfo.avatarUrl : require('../../assets/images/users/2.png')
      }
      return require('../../assets/images/users/2.png')
    },
    nickname() {
      if (this.$store) {
        this.currentUser = this.$store.state.current_user
        return this.currentUser && this.currentUser.userInfo && this.currentUser.userInfo.nickname ? this.currentUser.userInfo.nickname : '-'
      }
      return '-'
    },
    name() {
      if (this.$store) {
        this.currentUser = this.$store.state.current_user
        return this.currentUser && this.currentUser.userInfo && this.currentUser.userInfo.name ? this.currentUser.userInfo.name : '-'
      }
      return '-'
    }
  },
  methods: {
    logout() {
      this.$store.commit('logOut')
      this.$router.push("/auth/login");
    },
  },
  data: () => ({
    currentUser: null,
  }),
}
</script>
<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;

  }
}
</style>
