import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'font-awesome/css/font-awesome.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueI18n from 'vue-i18n'
import {createSimpleTransition} from 'vuetify/lib/components/transitions/createTransition'

const myTransition = createSimpleTransition('my-transition')

Vue.component('my-transition', myTransition)
Vue.use(VueI18n)
Vue.use(Vuetify)


Vuetify.config.silent = true

Vue.use(Vuetify);

export default new Vuetify({

    breakpoint: {
        scrollBarWidth: 16,
        thresholds: {
            xs: 600,
            sm: 960,
            md: 1280,
            lg: 1920,
        },
    },
    icons: {
        iconfont: 'mdi',
        values: {},
    },

    rtl: false,
    theme: {
        dark: false,
        default: 'light',
        disable: false,
        options: {
            cspNonce: undefined,
            customProperties: undefined,
            minifyTheme: undefined,
            themeCache: undefined,
            variations: false,
        },
        themes: {
            light: {
                primary: '#25282A',
                white: '#FFFFFF',
                accent: '#63E522',
                secondary: '#3c4144',
                success: '#56CA00',
                info: '#3b5998',
                warning: '#FFB400',
                error: '#FF2802',
                gray: '#D0D3D4',
                'gray-light': '#F3F4F4',
                roseQuartz: '#F5CAC3',
                lilla: '#C1CBF4',
                mikadoYellow: '#FFBE0B',
                dodgerBlue: '#3A86FF',
            },
            dark: {
                white: '#FFFFFF',
                primary: '#52595e',
                accent: '#63E522',
                secondary: '#3c4144',
                success: '#56CA00',
                info: '#3b5998',
                warning: '#FFB400',
                error: '#FF4C51',
            },
        },
    },
});
