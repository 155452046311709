<template>
  <v-list subheader flat color="transparent" two-line class="mt-15">
    <v-list-item>
      <v-list-item-content>

        <v-col cols="12">
        <v-card
    class="mx-auto text-center"
    color="primary"
    dark
   
  >
    <v-card-text>
      <v-sheet color="rgba(0, 0, 0, .12)">
        <v-sparkline
          :value="value"
          color="rgba(255, 255, 255, .7)"
          height="100"
          padding="24"
          stroke-linecap="round"
          smooth
        >
          <template v-slot:label="item">
            ${{ item.value }}
          </template>
        </v-sparkline>
      </v-sheet>
    </v-card-text>


    <v-divider></v-divider>

    <v-card-actions class="justify-center">
      <v-btn
        block
        text
      >
       TRADER
      </v-btn>
    </v-card-actions>
  </v-card>
        </v-col>
         <v-col cols="12">
                  <v-card
    class="mx-auto text-center"
    color="secondary"
    dark
   
  >
    <v-card-text>
      <v-sheet color="rgba(0, 0, 0, .12)">
        <v-sparkline
          :value="value"
          color="rgba(255, 255, 255, .7)"
          height="100"
          padding="24"
          stroke-linecap="round"
          smooth
        >
          <template v-slot:label="item">
            ${{ item.value }}
          </template>
        </v-sparkline>
      </v-sheet>
    </v-card-text>


    <v-divider></v-divider>

    <v-card-actions class="justify-center">
      <v-btn
        block
        text
      >
     PROFIT
      </v-btn>
    </v-card-actions>
  </v-card>
        </v-col>
        <v-col cols="12">
               <v-card
    class="mx-auto text-center"
    color="accent"
    dark
   
  >
    <v-card-text>
      <v-sheet color="rgba(0, 0, 0, .12)">
        <v-sparkline
          :value="value"
          color="rgba(255, 255, 255, .7)"
          height="100"
          padding="24"
          stroke-linecap="round"
          smooth
        >
          <template v-slot:label="item">
            ${{ item.value }}
          </template>
        </v-sparkline>
      </v-sheet>
    </v-card-text>


    <v-divider></v-divider>

    <v-card-actions class="justify-center">
      <v-btn
        block
        text
      >
       Sales
      </v-btn>
    </v-card-actions>
  </v-card>
        </v-col>
        
        <v-col cols="12">
        
        </v-col>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
  export default {
    data: () => ({
      value: [
        423,
        446,
        675,
        510,
        590,
        610,
        760,
      ],
    }),
   

      }

  
</script>
<style scoped>

</style>