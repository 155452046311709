<template>
  <v-app id="inspire">

    <v-app-bar app
               clipped-right
               flat
               :dark="$vuetify.theme.dark"
               elevation="6"
               height="72">
      <v-spacer></v-spacer>
      <base-top-menu></base-top-menu>
    </v-app-bar>
    <v-navigation-drawer permanent v-model="drawer" app width="240" :dark="$vuetify.theme.dark">
      <base-left></base-left>
    </v-navigation-drawer>

    <v-sheet class="overflow-y-auto" height="100vh">
      <v-main >
        <router-view></router-view>
      </v-main>
    </v-sheet>
  </v-app>
</template>

<script>
import BaseTopMenu from './Base/BaseTopMenu'
import DrawerVertical from './Drawer/DrawerVertical'
import DrawerHorizontal from './Drawer/DrawerHorizontal'
import BaseLeft from './Base/BaseLeft'
import BaseRight from './Base/BaseRight'

export default {
  components: {
    BaseTopMenu,
    DrawerVertical,
    DrawerHorizontal,
    BaseLeft, BaseRight
  },
  props: ['hover'],
  data: () => ({
    drawer: null
  }),
}
</script>
<style>
.theme--light {
  color: rgba(94, 86, 105, 0.68) !important;
}

.logo-horizontal img {
  padding-top: 15px
}
</style>
