// @ts-nocheck
'use-script'
/**
 * menu() returns menu json the order
 * @param <String> tag
 * @return <Element> element
 */
const Menu = []


export default Menu
