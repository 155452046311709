<template>
  <div>
    <div>
      <img
        src="../../assets/images/logo.png"
        height="100"
        width="100%"
        class="mt-4 mb-4"
        style="object-position: center; object-fit: contain"
      />
      <span class="text-center d-block mb-4 font-weight-bold">ADMIN PANEL</span>
    </div>
    <v-divider dark></v-divider>
    <div v-for="x in left" :key="x.id">
      <div>
        <v-btn
          height="60"
          elevation="0"
          width="100%"
          color="transparent"
          :to="{ path: '/' + x.path }"
          :dark="$vuetify.theme.dark"
          class="justify-start rounded-0"
        >
          <div style="width: 30px">
            <v-icon class="d-block" size="20">
              {{ x.icon }}
            </v-icon>
          </div>
          <span>{{ x.title }}</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    source: String,
  },
  computed: mapState(["current_user"]),
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "updateCurrentUser") {
        if (
          state.current_user &&
          state.current_user.role === "ADMIN" &&
          !this.left.find((l) => l.id === 3)
        ) {
          this.left.push(
            {
              id: 3,
              title: "Smart contracts",
              icon: "shield",
              path: "token-contracts",
            },
            {
              id: 4,
              title: "Wallets",
              icon: "mdi-wallet",
              path: "wallets",
            },
            {
              id: 5,
              title: "Transactions",
              icon: "mdi-swap-vertical",
              path: "transactions",
            }
          );
        }
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  data() {
    const dataToReturn = {
      currentUser: this.$store.state.current_user,
      drawer: null,
      left: [
        {
          id: 1,
          title: "Users",
          icon: "perm_identity",
          path: "users",
        },
      ],
      sheet: false,
    };
    if (dataToReturn.currentUser?.role === "ADMIN") {
      dataToReturn.left.push(
        {
          id: 3,
          title: "Smart contracts",
          icon: "shield",
          path: "token-contracts",
        },
        {
          id: 4,
          title: "Wallets",
          icon: "mdi-wallet",
          path: "wallets",
        },
        {
          id: 5,
          title: "Transactions",
          icon: "mdi-swap-vertical",
          path: "transactions",
        }
      );
    }
    return dataToReturn;
  },
  methods: {
    goGroup(x, y) {
      let vm = this;
      vm.$router.push(`/${x}/${y}`);
    },
    goPage(x, y) {
      let vm = this;
      vm.$router.push(`/${x}/${y}`);
    },
  },
  computed: {
    bg() {
      return this.appConfig.background
        ? "https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
        : undefined;
    },
  },
};
</script>
<style scope>
.menu-color {
  background: #6d5dfc;
}
</style>
