<template>
  <v-app class="app">
    <template v-if="!$route.meta.public">
      <transition name="slide-fade">
        <app-layout></app-layout>
      </transition>
    </template>
    <template v-else>
      <router-view :key="$route.fullPath"></router-view>
    </template>
  </v-app>
</template>

<script>

import AppLayout from "./components/AppLayout";
import axios from "axios";

export default {
  name: "App",

  props: {
    attrs: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.$store.commit('initIsLoggedIn')
  },
  components: {AppLayout},
  data: () => {
    return {
      show: true
    }
  },
};
</script>


<style>
/* Animações de entrada e saída podem utilizar diferentes  */
/* funções de duração e de tempo.                          */
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active em versões anteriores a 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

html {
  font-size: 16px;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  box-sizing: border-box;
  overflow-y: hidden !important;
  -webkit-text-size-adjust: 100%;
  word-break: normal;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
</style>
