<template>
  <div class="scrollbar scrollbar-primary">
    <v-list class="pl-14">
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title>
            <img width="190px" src=".././../assets/images/logo.png">
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list class="pl-14" shaped>
      <v-list-group
          dark
          class="menu-list"
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          no-action
          :color="item.active ? 'primary' : ''"
          v-bind:class="{ active: isActive }"
      >
        <template v-slot:activator>
          <v-list-item-icon class="menu-icon">
            <v-icon color="primary" v-text="item.action"></v-icon>
          </v-list-item-icon>
          <v-list-item-content class="menu-title">
            <v-list-item-title
                dark
                class="subtitle-1 text-grey"
                v-text="item.title"
            ></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
            class="menu-item"
            active-class="primary white--text"
            v-for="subItem in item.items"
            :key="subItem.title"
            :to="{ path: '/' + subItem.path }"
        >
          <v-list-item-content class="menu-subtitle">
            <v-list-item-title
                v-bind="$attrs"
                v-bind:class="{ active: isActive }"
                v-text="subItem.title"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-col
          cols="12"
          sm="12"
      >

      </v-col>
    </v-list>
  </div>
</template>
<script>
import menu from "../../plugins/menu.js";

export default {
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
    isActive: true,
    active: true,
    mini: false,
    navbar: true,
    items: menu,
    bottomNav: "recent",
  }),
  methods: {
    goGroup(x, y) {
      let vm = this;
      vm.$router.push(`/${x}/${y}`);
    },
    goPage(x, y) {
      let vm = this;
      vm.$router.push(`/${x}/${y}`);
    },
  },

};
</script>
<style>
.menu-list {
  font-size: 12px;
}

.menu-title {

}

.base-drawer {
  height: 100% !important
}
</style>
