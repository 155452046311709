import router from "@/router";
import { default as axios, default as globalAxios } from "axios";
import Vue from "vue";
import cookie from 'vue-cookies';
import Vuex from "vuex";

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: { logged_in: false, current_user_id: null, current_user: null },
    getters: {},
    mutations: {
        initIsLoggedIn: (state) => {
            state.logged_in = !!cookie.get('bearerAuth')
            if (state.logged_in) {
                state.current_user_id = cookie.get('current_user_id')
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + cookie.get('bearerAuth');
                axios.get(process.env.VUE_APP_BASE_API_URL + `/admin/user/${state.current_user_id}`).then(resp => {
                    store.commit('updateCurrentUser', resp.data)
                })
            }
            return !!cookie.get('bearerAuth');
        },
        updateCurrentUser(state, user) {
            state.current_user = {
                ...user,
            }
        },
        logOut(state) {
            state.logged_in = false
            cookie.remove('bearerAuth');
        }
    },
})

globalAxios.interceptors.response.use(
    response => {
        return response;
    },
    async (error) => {
        let status = error.response?.status;
        if (
            status === 403
        ) {
            store.commit('logOut')
            router.push('/auth/login')
            return Promise.reject(error);
        }
        return Promise.reject(error);
    },
);
