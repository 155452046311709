<template>

      <v-container class="">
        <v-menu transition="scroll-y-transition" v-for="item in menu" :key="item" open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text centered color="primary"   small v-bind="attrs" v-on="on">
              <v-icon   small class="ml-2" dark>
                {{item.action}}
              </v-icon>
              {{item.title}}
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="subItem in item.items" :key="subItem.title" :to="{ path: '/' + subItem.path }">
              <v-list-item-title>{{subItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-container>


</template>
<script>
  import BaseTopMenu from '../Base/BaseTopMenu'
  import menu from "../../plugins/menu.js";
  export default {
    name: "drawer-horizontal",
    components: {
      BaseTopMenu
    },
    props: {
      source: String,
    },
    data: () => ({
      expand: false,
      drawer: null,
      isActive: true,
      active: true,
      mini: false,
      navbar: true,
      menu: menu,
      bottomNav: "recent",
    }),
    methods: {
      goGroup(x, y) {
        let vm = this;
        vm.$router.push(`/${x}/${y}`);
      },
      goPage(x, y) {
        let vm = this;
        vm.$router.push(`/${x}/${y}`);
      },
    },
    computed: {
      bg() {
        return this.appConfig.background ?
          "https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg" :
          undefined;
      },
    },
  };
</script>
<style>
.logo-horizontal img{
  width:180px;
  padding:10px;

}
.drawer-horizontal{
  margin-bottom:150px;
}

</style>