import Vue from 'vue'
import App from './App.vue'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import { store } from '@/plugins/store'
import Transitions from 'vue2-transitions'
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';


import VueCookies from 'vue-cookies'

Vue.use(VueCookies, { expire: '1d' })

Vue.use(Transitions)
Vue.use(Toast, { timeout: 3000 });
Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app')
